@import "layout/sass/_colors";
@import "layout/sass/_variables";
@import "layout/sass/_media-queries";

.dictionary {
    .dictionary__list-item {
        background-color: $backgroundPrimaryLighter;
        box-shadow: $boxShadow;
        padding: $panelsPadding;
        margin-bottom: $panelsPadding / 2;

        &:hover {
            background-color: $backgroundPrimaryLight;
            box-shadow: $boxShadowDarker;
        }

        .dictionary__item-title {
            border: none !important;
            padding: 0px !important;
            margin-top: 8px;
        }
        .dictionary__item-subtitle {
            margin-top: 8px 0;
            :global {
                dt {
                    float: left;
                    color: $textColor;
                    margin-right: $panelsPadding;
                }
                dd {
                    color: $textColor;
                    margin-bottom: 0px;
                }
            }
        }
    }

    :global {
        .p-dataview-content {
            margin-bottom: $panelsPadding;
        }
        .p-paginator {
            &.p-paginator-bottom {
                padding-bottom: $panelsPadding;
            }
            .p-paginator-current {
                margin-right: $panelsPadding;
                
                @include media-breakpoint-below(md) {
                    display: none;
                }
            }
            
            .p-paginator-first,
            .p-paginator-prev {
                margin-right: 2px !important;
                background: $backgroundPrimaryLighter !important;
                color: $foregroundPrimaryLight !important;
                border: none !important;
                height: 32px;
                width: 32px;
    
                &.p-highlight,
                &:hover {
                    background: $defaultColor !important;
                    color: white !important;
                }
            }
    
            .p-paginator-pages {
                margin-left: 4px !important;
                margin-right: 4px !important;
                .p-paginator-page {
                    background: $backgroundPrimaryLighter !important;
                    color: $foregroundPrimaryLight !important;
                    border: none !important;
                    margin: 0 1px !important;
                    height: 32px;
                    width: 32px;
    
                    &.p-highlight,
                    &:hover {
                        background: $defaultColor !important;
                        color: white !important;
                    }
                }
            }
            
            .p-paginator-last,
            .p-paginator-next {
                margin-left: 2px !important;
                background: $backgroundPrimaryLighter !important;
                color: $foregroundPrimaryLight !important;
                border: none !important;
                height: 32px;
                width: 32px;
                
                &.p-highlight,
                &:hover {
                    background: $defaultColor !important;
                    color: white !important;
                }
            }
            .p-dropdown {
                width: auto!important;
                background: $backgroundPrimaryLighter !important;
                border: $borderLight;
    
                &:hover {
                    .p-dropdown-label {
                        color: $foregroundPrimaryLight !important;
                    }
                }
                .p-dropdown-label {
                    padding: 5px 8px !important;
                    border: none;
                    border-radius: 0;
                }
                .p-dropdown-trigger {
                    width: 32px;
                    color: $borderPrimary !important;
                    border-radius: 0;
                    background: transparent;
                }
            }
        }
    }
}