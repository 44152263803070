@import "layout/sass/_colors";
@import "layout/sass/_variables";

.article {
    margin-top: $panelsPadding * 2;

    .article__body * {
        font-family: $fontFamily !important;
        font-size: $fontSize !important;
        text-align: justify !important;
    }
}

:global {
    .p-galleria {
        // .p-galleria-close {
        //     top: 80px;
        // }
        .p-galleria-item-nav,
        .p-galleria-close {
            margin: 0.5rem;
            background: transparent;
            color: $foregroundOverAccent;
            width: 4rem;
            height: 4rem;
            transition: background-color 0.2s, color 0.1s, box-shadow 0.2s;
            border-radius: 50%;

            .p-galleria-item-prev-icon,
            .p-galleria-item-next-icon,
            .p-galleria-close-icon {
                font-size: 2rem;
            }

            &:hover {
                color: $defaultColor;
            }
        }
    }
    .p-galleria-mask.p-component-overlay {
        background-color: rgba(0, 0, 0, 0.9);
    }
}