@import "src/layout/sass/colors";
@import "src/layout/sass/variables";

.banner {
    background-image: url("/images/bg-1920x1000.jpg");
    background-attachment: initial !important;
}
.title-wrapper {
    margin-top: $panelsPadding * 2;

    .title {
        font-variant: small-caps;
        border: 2px solid $defaultColor;
        padding: ($panelsPadding/2) $panelsPadding;
    }

    .options {
        :global {
            .p-dropdown {
                color: $defaultColor;
                font-variant: small-caps;
                font-weight: 600;
                border: 2px solid $defaultColor;
                padding: ($panelsPadding/2) ($panelsPadding/2) ($panelsPadding/2) $panelsPadding;
                
                .p-dropdown-label {
                    padding-right: $panelsPadding/2;
                }

                .p-dropdown-panel {
                    left: unset !important;
                    right: 0;
                    box-shadow: $boxShadow;
                    
                    .p-dropdown-item {
                        padding: 4px 8px;
                        background-color: white;
                        color: $defaultColor;
                        font-weight: 600;

                        &:hover {
                            background-color: $backgroundSecondary;
                            color: $foregroundOverAccent;
                        }
                    }
                }
            }
        }
    }
}