
$defaultColor: #d8132f;
$defaultColorLight: lighten(#d8132f, 10%);
$defaultColorLighter: lighten(#d8132f, 20%);
$defaultColorDark: darken(#d8132f, 10%);
$defaultColorDarker: darken(#d8132f, 20%);
$defaultColorAlpha: rgba(216,19,47,0.1);
$defaultColorShadow: rgba(0, 0, 0, 0.1);
$defaultColorShadowDarker: rgba(0, 0, 0, 0.3);

$textColor: #303030;
$textSecondaryColor: $defaultColor;

$inputFocusBorderColor: $defaultColorLight;

$backgroundPrimary: #f2f2f2;
$backgroundPrimaryLight: #fafafa;
$backgroundPrimaryLighter: #ffffff;
$backgroundSecondary: #938f67;
$backgroundSecondaryLight: rgba(147, 143, 103, 0.3);
$backgroundDark: #181818;
$backgroundMask: rgba(0, 0, 0, 0.3);

$foregroundPrimary: #212121;
$foregroundPrimaryLight: #757575;
$foregroundPrimaryLighter: #a2a2a2;
$foregroundOverAccent: #ffffff;

$borderPrimary: #bcbcbc;
$borderPrimaryLight: #dedede;
$borderPrimaryLighter: #ededed;

$boxShadow: 0 0 5px 0 $defaultColorShadow;
$boxShadowDarker: 0 0 5px 0 $defaultColorShadowDarker;
$statusPositive: #649c64;