@import "layout/sass/_colors";
@import "layout/sass/_variables";

.team {
    padding: ($panelsPadding * 2) 0 $panelsPadding;

    .team-container {
        :global {
            .p-tabview-nav {
                border-bottom: 1px solid #dee2e6;
                
                li {
                    padding: 15px;
                    margin-bottom: 1px;
                    
                    &:hover {
                        color: $defaultColor;
                        border-bottom: 2px solid $textColor;
                        margin-bottom: 0px;
                    }

                    &.p-tabview-selected {
                        color: $defaultColor;
                        border-bottom: 2px solid $defaultColor;
                        font-weight: 600;
                        margin-bottom: 0px;
                    }
                    .p-tabview-nav-link {
                        &:hover {
                            text-decoration: none !important;
                        }

                        .p-tabview-title {
                            color: $defaultColor;
                            font-size: 20px !important;
                            font-family: DINNeuzeitGrotesk, Verdana, Helvetica, sans-serif !important;
                            font-variant: small-caps;
                            font-weight: 400;
                            line-height: 1.3em;
                        }
                    }
                }
            }
            .p-tabview-panels {
                padding: 10px;
            }

            .team__member {
                .p-fieldset-legend {
                    a {
                        font-size: $fontSize;
                        color: $textColor;
                        justify-content: normal;

                        .team__member-name {
                            &:hover {
                                color: $defaultColor;
                            }
                        }
                        .p-fieldset-toggler { display: none; }
                    }
                }
            }
        }
    }
}