@import "src/layout/sass/colors";
@import "src/layout/sass/variables";

.contact-form {
    background-color: $backgroundPrimary !important;
    color: $textColor;
    padding-top: $panelsPadding !important;
    padding-bottom: $panelsPadding !important;

    .send-button {
        float: right;
        font-variant: small-caps;
    }
}

.footer {
    //padding-top: 8px !important;
    padding-bottom: 8px !important;
    
    .partners {
        display: block;
        text-align: center;
        padding: $panelsPadding*2 0px;

        :global {
            img {
                height : 50px;
                padding-left: $panelsPadding;
            }
        }
    }
    
    .disclaimer {
        text-align: center;
        font-size: 10px;
        color: white;
    }
}