@import "layout/sass/_colors";
@import "layout/sass/_variables";

.project {
    margin-top: $panelsPadding * 2;

    .project__body * {
        font-family: $fontFamily !important;
        font-size: $fontSize !important;
        text-align: justify !important;
    }
    :local .project__body {
        :global {
            h3 {
                font-variant: small-caps !important;
                color: $defaultColor;
                font-size: 18px !important;
                font-weight: 600;

                em {
                    font-size: 18px !important;
                    font-weight: 600;
                }
            }
        }
    }
}