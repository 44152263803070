@import "layout/sass/_colors";
@import "layout/sass/_variables";

.team-member__wrapper {
    background-color: $backgroundPrimaryLight;
    margin-bottom: $panelsPadding;
    padding: $panelsPadding;

    :global h2 {
        display: block;
    }

    .team-member__photo {
        padding: $panelsPadding 0;
        max-width: 300px;
        margin-right: auto;
        
        :global img {
            width: 100%;
        }
    }
    .team-member__novels {
        list-style: none;
        margin-left: 0px;

        :global li {
            background-color: $backgroundPrimary;
            margin: 6px 4px;
        }

        .team-member__novel {
            font-size: 13px;
            padding: 5px;

            &:hover {
                box-shadow: $boxShadow;
            }

            .team-member__novel-icon {
                margin-right: 8px;
                color: $statusPositive;   
            }
            .team-member__novel-year {
                font-weight: 600;
                margin-right: 8px;
            }
            .team-member__novel-title {
                font-style: italic;

                a {
                    color: $textColor !important;

                    &:hover {
                        color: $defaultColor !important;
                        // text-decoration: underline !important;
                    }
                }
            }
            .team-member__novel-info {
                margin-left: 8px;
            }
        }

        :global {
            .p-avatar {
                background-color: $backgroundSecondaryLight;
                color: $defaultColor;
                margin-right: 4px;
                float: left;

                .p-badge {
                    background-color: $defaultColor;
                    color: white;
                    font-size: 0.7rem;
                    font-weight: 700;
                    min-width: 1rem;
                    height: 1rem;
                    line-height: 1rem;
                    top: 2px;
                    right: 2px;
                }
            }
        }
    }

    :global {
        .no-entrance {
            color: $defaultColor !important;
        }
    }
}

:global {
    .p-sidebar-close {
        font-size: 32px;
        top: 15px !important;
        right: 15px !important;
    }
}