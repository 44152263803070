@import "layout/sass/_colors";
@import "layout/sass/_variables";

.dictionary__filters {
    //position: relative;
    margin: -$panelsPadding 0 $panelsPadding;

    :global {
        .p-panel {
            font-weight: 600;
            font-variant: small-caps;
            min-height: 32px;
            width: 100%;
            background-color: $backgroundSecondaryLight;
            box-shadow: $boxShadow;

            .p-panel-header {
                padding: $panelsPadding /2;
                .p-panel-icons {
                    button {
                        // height: 32px;
                        // width: 32px;
                        color: $defaultColor;
                        // position: absolute;
                        // right: 15px;
                        // top: -40px;

                        .pi-minus, .pi-plus {
                            font-family: $iconFontFamily;
                            
                            &:before {
                                content: "\f0b0";
                            }
                        }
                    }
                }
            }
            .p-panel-content {
                padding: 0 $panelsPadding $panelsPadding;
                .p-inputtext {
                    width: 90%;
                }
                .p-float-label {
                    label {
                        left: 8px;
                        transition: all 0.25s ease 0s;
                    }
                }
            }
        }
    }
}