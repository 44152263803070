@import "src/layout/sass/colors";
@import "src/layout/sass/mixins";
@import "src/layout/sass/variables";

.sidebar {
	overflow-y: auto;
	overflow-x: hidden;
	padding: 0 !important;
	border: 1px solid $borderPrimaryLight;
	max-width: 100%;
}

body {
	.single {
		width: 320px;
		max-width: 100%;
	}
	.double {
		width: 640px;
		max-width: 100%;
	}
	.triple {
		width: 960px;
		max-width: 100%;
	}
	.full {
		width: 100%;
	}
}

.p-sidebar {
    background-color: $backgroundPrimaryLight !important;
    color: $textColor;
    padding: $panelsPadding;
    border: $overlayContentBorder;
    @include shadow($overlayContainerShadow);
    z-index: 1010;

	.sidebar-header {
		background-color: $backgroundPrimary !important;
		width: 100%;
		padding-left: $panelsPadding;
		padding-top: $panelsPadding;
		position: sticky;
		top: 0;
		z-index: 100;
		height: 40px;
		font-size: $fontSizeTitle;
		line-height: 23px;
	}
	.sidebar-body {
		padding: 0;
	}
	.p-sidebar-close {
        color: $textSecondaryColor;
		position: absolute;
		margin-right: $panelsPadding;
		margin-top: $panelsPadding;
		text-align: center;
		width: 1.25rem;
		height: 1.25rem;
		line-height: 1.25rem;
		z-index: 200;
		top: 0;

        &:hover {
            color: $textColor;
        }

		// .p-sidebar-close-icon.pi.pi-times {
		// 	@include icon-content($fa-var-times);
		// }
	}
	&.sidebar-untitled {
		.sidebar-header {
			display: none;
		}
		.p-sidebar-close {
			display: none;
		}
	}
}
.sidebar-overlay {
    display: none;
    background-color: $backgroundMask;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1005;

    &.visible {
        display: block;
    }
}