@import "colors";

$fontFamily: 'Open Sans', sans-serif;
$titleFontFamily: "DINNeuzeitGrotesk", Verdana, Helvetica, sans-serif;
$iconFontFamily: "Font Awesome 5 Pro";
$fontSize: 14px;
$fontSizeTitle: 20px;
$borderRadius: 3px;
$transitionDuration: 0.3s;

// Switx variables
$borderDark: 1px solid $borderPrimary;
$borderLight: 1px solid $borderPrimaryLight;
$borderLighter: 1px solid $borderPrimaryLighter;
$inputHeight: 34px;
$inputDisabledOpacity: 0.5;
$focusShadow: 0 0 0 1px $inputFocusBorderColor;

$panelContentBorder: 1px solid $borderPrimaryLight;
$panelsPadding: 15px; // same as bootstrap row margin 

$overlayContentBorder: $panelContentBorder;
$overlayContainerShadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);