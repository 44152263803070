@import "../colors";
@import "../variables";

@font-face {
    font-family: DINNeuzeitGrotesk;
    src: url("/fonts/DINNeuzeitGrotesk-BoldCond-Regular.ttf") format("truetype"), url("/fonts/DINNeuzeitGrotesk-BoldCond-Regular.otf") format("opentype");
}

body {
	background-color: $backgroundPrimary;
    .p-card {
        background-color: $backgroundPrimaryLighter;
        border: 1px solid $borderPrimaryLight;
        border-radius: $borderRadius;
        
        .p-card-body {
            padding: $panelsPadding;
            // datatable toolbar menus need 'overflow' to be set to 'initial' value in order to show
            overflow: initial; //hidden;
        }
    }
}

h2 {
	color: $defaultColor;
    font-size: 24px!important;
    font-family: DINNeuzeitGrotesk, Verdana, Helvetica, sans-serif !important;
    //font-weight: 600;
    font-variant: small-caps;
    border: 2px solid $defaultColor;
    padding: ($panelsPadding/2) $panelsPadding;
    display: inline-block;
}

a {
	color: $defaultColor !important;

	&:hover {
		text-decoration: none !important;
	}
}

.button {
    font-variant: small-caps;
    background-color: $defaultColor;
    font-family: DINNeuzeitGrotesk, Verdana, Helvetica, sans-serif !important;
    letter-spacing: 1px;
    color: white;

    &.p-disabled {
        opacity: 0.5;
    }
}

.site-header {
	background-color: white;

    .navbar {
        .navbar-brand {
            margin: 8px 0;
        }

        .nav-item {
            margin-bottom: 2px;
            &:last-child {
                .nav-link {
                    margin-right: 1.25rem !important;
                }
            }
            &:hover {
                // background-color: $defaultColorAlpha;
                margin-bottom: 0px;
                border-bottom: 2px solid $defaultColor;
            }
            .nav-link {
                padding: 0;
                font-family: $titleFontFamily;
                font-variant: small-caps;

                &:hover {
                    text-decoration: none !important;
                }
            }
        }
        &.fixed-bottom:not(.fixedmenu) {
            .navbar-brand {
                display: none;
            }
        }
    }

    .side-menu {
        .inner-wrapper {
            background-image: url("/images/watermark-right-opac30.png");
            background-position: bottom right;
            background-repeat: no-repeat;
            background-size: 50%;
        }
        .btn-close {
            box-shadow: none;
        }

        .nav-link {
            font-family: $titleFontFamily;
            font-variant: small-caps;
            font-size: 18px !important;
        }
    }
}
.page-header {
    min-height: 350px;

    .page-titles {
        min-height: 280px;
        padding-top: 6rem;
        padding-bottom: 5rem;
    }
}
.layout-content {    
    background-image: url("/images/watermark-right.png");
    background-position: bottom right;
    background-repeat: no-repeat;
    color: $textColor;
}

.heading_space {
    margin-bottom: $panelsPadding !important;
}

#presentation, #partners,
#education-detail,
#published-detail,
#research-detail {
    h2 {
        text-align: left;
        font-size: 2em;
    }
    h3 {
        color: $defaultColor;
    }
    ul {
        margin-left: 28px;
        
        li {
            margin-bottom: 8px;

            &:before {
                content: "\25AA";
                color: $defaultColor;
                font-size: 16px;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }
        }
    }
}
.sidebar {
    ul {
        margin-left: 18px;
    }
    h3 { font-size: 1.3em;}
    h4 {
        color: $defaultColor;
        margin-bottom: 8px;
    }
}

#education-detail,
#published-detail {
    p {
        margin-bottom: 10px;
    }
    h2 {
        font-size: 2em;
    }
    .news_desc {
        a {
            color: $defaultColor;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

#accordion {
    h2 {
        font-size: 1.3em;
    }
    .label {
        font-weight: 600;
    }
}

.no-image {
    height: 200px;
    background-color: #acacac;
    text-align: center;
        
    i {
        position: absolute;
        color: #303030;
        font-size: 52px;
        top: 70px;
        left: 150px;
    }
}

#testimonial-slider {
    .item {
        h4 {
            width: 80%;
            margin-left: 45px;
        }
    }
}

#our-partners,
#site-footer {
    padding: 3.5em;
}

.fa, .far, .fas {
    font-family: $iconFontFamily !important;
}