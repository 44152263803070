@import "layout/sass/_colors";
@import "layout/sass/_variables";
@import "layout/sass/_media-queries";

.biblio {
    padding-top: $panelsPadding * 2;
    
    .biblio__list-item {
        display: block;
        background-color: $backgroundPrimaryLighter;
        box-shadow: $boxShadow;
        margin-bottom: $panelsPadding / 2;
        height: fit-content;
        width: 100%;

        &:hover {
            background-color: $backgroundPrimaryLight;
            box-shadow: $boxShadowDarker;
        }
        
        .biblio__list-item-body * {
            font-family: $fontFamily !important;
            font-size: $fontSize !important;
            text-align: justify !important;
        }

        :global {
            .card-header {
                font-weight: 600;
                font-variant: small-caps;
    
                .card-link {
                    &:before {
                        content: "\f068";
                    }
                    &.collapsed {
                        &:before {
                            content: "\f067";
                        }
                    }
                }
            }
        }
    }
}