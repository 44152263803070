@import "layout/sass/_colors";
@import "layout/sass/_variables";

.novel__subtitle {
    margin-top: $panelsPadding;
    color: $textColor;
    
    :global dl {
        dt {
            float: left;
            margin-right: 8px;
            font-variant: small-caps;
        }
    }
}

.novel {
    .novel__body * {
        font-family: $fontFamily !important;
        font-size: $fontSize !important;
        text-align: justify !important;
    }
    .novel__characters {
        :global {
            .p-accordion-tab {
                margin-bottom: $panelsPadding;

                .p-accordion-toggle-icon {
                    display: none;
                }

                .p-accordion-content {
                    padding: $panelsPadding 0;
                    border-bottom: 1px solid $borderPrimaryLighter;
                }
            }
        }
    }

    .novel__link-totals {
        margin-left: 15px;
    }
    :global {
        .card-header {
            font-weight: 600;
            font-variant: small-caps;

            .card-link {
                &:before {
                    content: "\f068";
                }
                &.collapsed {
                    &:before {
                        content: "\f067";
                    }
                }
            }
        }
    }
}