@import "layout/sass/_colors";
@import "layout/sass/_variables";

.sources__filters {
    display: block;

    :global {
        .p-button {
            color: $defaultColor;
            background-color: transparent !important;
            border: 2px solid $defaultColor;
            padding: 8px;
            margin: 4px 2px !important;

            .p-button-label {
                width: 16px;
                display: block;
            }

            &.active, &:hover {
                background-color: $defaultColor !important;
                color: white;
            }
        }
    }
}