@import "src/layout/sass/variables";

.title {
	font-size: 20px!important;
	font-weight: 600;
	display: contents;
	.title-annotation {
		opacity: 0.7;
		padding-left: $panelsPadding;
	}
}
li {	
	// Title inside tabs or li
	.title {
		font-size: $fontSize !important;
		line-height: $fontSize; // center vertically
	}
}
:global .page-header-slim {
	:local .title {
		display: inherit;
		padding-bottom: 4px;
	}
}
:global .page-header {
	:local .title {
		display: inline-flex;
		margin-right: $panelsPadding;
	}

	.p-menuitem-text {
		:local .title {
			margin-right: 0px;
		}
	}
}